import { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Helmet } from "react-helmet-async";

import { ListingCard, RentEstimations } from "../../components/listing";
import { MapContainer } from "../../components/listing/Map";
import { ListingCompactView, Estimation, Listing } from "../../../api/interfaces/listing";
import { styled } from "styled-components";
import { EstimationWithComparables } from "./components";
import { toTitleCase } from "../../utils";
// import { RentPerSquareFootChart } from "../../components/ChartMonthly";

// const exampleData = [
//   { month: "Sep 2023", pricePerSF: 1.85 },
//   { month: "Oct 2023", pricePerSF: 1.88 },
//   { month: "Nov 2023", pricePerSF: 1.90 },
//   { month: "Dec 2023", pricePerSF: 1.87 },
//   { month: "Jan 2024", pricePerSF: 1.92 },
//   { month: "Feb 2024", pricePerSF: 1.95 },
//   { month: "Mar 2024", pricePerSF: 1.98 },
//   { month: "Apr 2024", pricePerSF: 2.02 },
//   { month: "May 2024", pricePerSF: 2.08 },
//   { month: "Jun 2024", pricePerSF: 2.10 },
//   { month: "Jul 2024", pricePerSF: 2.15 },
//   { month: "Aug 2024", pricePerSF: 2.12 },
//   { month: "Sep 2024", pricePerSF: 2.18 },
//   { month: "Oct 2024", pricePerSF: 2.22 },
//   { month: "Nov 2024", pricePerSF: 2.25 },
//   { month: "Dec 2024", pricePerSF: 2.30 },
//   { month: "Jan 2025", pricePerSF: 2.35 },
//   { month: "Feb 2025", pricePerSF: 2.40 }
// ];

const ListingInfo = styled.div`
  margin: 20px 0px;
  color: #625f57;
`;

const ListingInfoCol = styled(Col)`
  border-right: 1px solid #dcdcea;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
  font-size: 16px;

  &.no-border {
    border: none;
  }
`;

const ListingInfoTitle = styled.div`
  // color: #8c8c8c;
  // font-weight: lighter;
  text-transform: uppercase;
  font-size: 16px;
`;

const ComparablesWrapper = styled.div`
  left: 12px;
  right: 12px;
  bottom: 12px;
`;

interface Props {
  comparables: ListingCompactView[];
  estimation: Estimation | null;
  listing: Listing;
  loading: boolean;
  handleComparablesUpdate: (comparablesIds: string[]) => Promise<void>;
}

export const Rentomatic = (props: Props) => {
  const [selectedListing, setSelectedListing] = useState<string | null>(null);
  const address = props.listing.unparsed_address ?? props.listing.full_address;

  return (

    <div className='mt-3'>
      <Helmet>
        <title>Rentomatic - {address}</title>
        <meta name="description" content={`View details for ${address}, including rent estimations and comparables.`} />
        <meta property="og:title" content={`Rentomatic - ${address}`} />
        <meta property="og:description" content={`View details for ${address}, including rent estimations and comparables.`} />
        <link rel="image_src" href="/images/self-logo-og.png" />
        <meta property="og:image" content="/images/self-logo-og.png" />
        <meta name="twitter:image:src" content="/images/self-logo-og.png" />
      </Helmet>

      <div className='mb-3' id="listing-card">
        <ListingCard listing={props.listing} />
        <ListingInfo>
          <Container id="listing-info-container">
            <Row>
              <ListingInfoCol>
                <ListingInfoTitle>subdivision</ListingInfoTitle>
                {toTitleCase(props.listing.subdivision_name ?? "Unknown")}
              </ListingInfoCol>
              <ListingInfoCol>
                <ListingInfoTitle>days on market</ListingInfoTitle>
                {Number.isNaN(props.listing.days_on_market)
                  ? "Unknown"
                  : props.listing.days_on_market}
              </ListingInfoCol>
              <ListingInfoCol className='no-border'>
                <ListingInfoTitle>original list price</ListingInfoTitle>
                {props.listing.original_list_price
                  ? `$${props.listing.original_list_price?.toLocaleString()}`
                  : "Unknown"}
              </ListingInfoCol>
            </Row>
          </Container>
        </ListingInfo>
      </div>
      <Container className='mb-3' id="estimations">
        {props.estimation && (
          <RentEstimations
            withEvaluations
            estimation={props.estimation}
            comparables={props.comparables}
            listing_price={props.listing.list_price}
            listing={props.listing}
          />
        )}
      </Container>

      {/* <div style={{ maxWidth: "800px", margin: "0 auto" }} className='mb-3' id="rent-per-sf-chart">
        <RentPerSquareFootChart data={exampleData} />
      </div> */}


      <ComparablesWrapper id="comparables">
        <div className='mb-3' id="map-container">
          <MapContainer
            selectedMarker={selectedListing}
            onMarkerSelect={(listingKey) => setSelectedListing(listingKey)}
            comparables={props.comparables}
            startingLat={props.listing.latitude}
            startingLng={props.listing.longitude}
            startingKey={props.listing.listing_key}
          />
        </div>
        <EstimationWithComparables
          {...props}
          selectedListing={selectedListing}
        />
      </ComparablesWrapper>
    </div>
  );
};
