import { useState } from "react";
import { useApi } from "../../../api/useApi";
import { Button, Form } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { LoadingIndicator } from "../../components/LoadingIndicator";

export const Login = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const state = location.state;
  const [username, setUsername] = useState(state?.email);
  const [password, setPassword] = useState(state?.password);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const context = useApi();
  const handleLogin = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setError(false);
    setIsLoading(true);
    try {
      const disclaimerState = {
        state: { diclaimer: true },
      }
      await context?.login(username, password);
      if (state?.from) {
        navigate(state.from, disclaimerState);
      }
      else {
        navigate(`/search`, disclaimerState);
      }
    } catch (error) {
      setError(true);
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <>
      <Form onSubmit={handleLogin}>
        <Form.Group className='mb-3' controlId='formBasicEmail'>
          <Form.Label>Email</Form.Label>
          <Form.Control
            defaultValue={state?.email}
            onChange={(e) => setUsername(e.target.value)}
            type='email'
            isInvalid={error}
            placeholder='Enter email'
          />
          <Form.Control.Feedback type='invalid'>
            Please make sure email is correct.
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group className='mb-3' controlId='formBasicPassword'>
          <Form.Label>Password</Form.Label>
          <Form.Control
            defaultValue={state?.password}
            onChange={(e) => setPassword(e.target.value)}
            type='password'
            isInvalid={error}
            placeholder='Password'
          />
          <Form.Control.Feedback type='invalid'>
            Please make sure password is correct.
          </Form.Control.Feedback>
        </Form.Group>
        <p className='mt-2' style={{ textAlign: "right" }}>
          <Link to='/accounts/reset'>Forgot Password?</Link>
        </p>
        <Button variant='primary' type='submit' disabled={isLoading}>
          {isLoading ? (
            <>
              <LoadingIndicator />
              &nbsp; Loading...
            </>
          ) : (
            "Login"
          )}
        </Button>
        <p className='mt-2 text-center'>
          <strong>Don’t have an account?{" "}
          <Link to='/accounts/register'>Create Account</Link>
          </strong>
        </p>
      </Form>
    </>
  );
};
