import { ReactNode } from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  display: flex;
  // width: 141px;
  height: 99px;
  padding: 15px 10px;
  flex-direction: column;
  // align-items: flex-start;
  gap: 1.921px;
  flex-shrink: 0;

  border-radius: 8px;
  border: 1.921px solid #6a76a0;
  background: #1a233d;

  color: #fff;
  text-align: center;
  font-size: 28.808px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* 43.212px */

  span.panelTitle {
    color: #fff;
    text-align: center;
    font-size: 16.8px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
`;

interface Props {
  value: ReactNode;
  text: string;
}

export const Panel = (props: Props) => {
  return (
    <Wrapper className='withBoxShadow'>
      <span>{props.value}</span>

      <span className='panelTitle'>{props.text}</span>
    </Wrapper>
  );
};
