import { Col, Row } from "react-bootstrap";
import { Mail, MessageCircle, Linkedin, Shield } from "react-feather";

import { Listing } from "../../../api/interfaces/listing";
import { styled } from "styled-components";

interface Props {
  listing?: Listing;
}

const ContactDetailsRow = styled(Row)`
  margin-bottom: 5px;

  a,
  span {
    font-weight: normal;
    text-decoration: none;
  }
`;



export function ContactDetails({ listing }: Props) {
  if (!listing) {
    return (
      <ContactDetailsRow>
        <Col xs={12}>
          Not available. Use the contact form on the listing page.
        </Col>
      </ContactDetailsRow>
    );
  }

  // agent name parts for LinkedIn and TREC links
  const name_parts = listing?.list_agent_full_name?.split(" ") ?? [];
  const keywords = name_parts.join("%20");
  const linkedInUrl = `https://www.linkedin.com/search/results/people/?keywords=${keywords}`;
  const LinkedInLink = (
    <a href={linkedInUrl} target="_blank" rel="noopener noreferrer">
      <Linkedin size={20} style={{ marginRight: 10 }} />
    </a>
  );

  // broker name parts for LinkedIn and TREC links
  const broker_name_parts = listing?.list_office_name?.split(" ") ?? [];
  const broker_keywords = broker_name_parts.join("%20");

  const trecAgentLink = `https://www.trec.texas.gov/apps/license-holder-search/?lic_name=${keywords}&industry=Real+Estate&county=&display_status=&lic_hp=&ws=857&license_search=Search`;
  console.log("TREC Link:", trecAgentLink);

  const trecAgentLicenseLink = `https://www.trec.texas.gov/apps/license-holder-search/?lic_name=${listing.list_agent_mls_id}&industry=Real+Estate&county=&display_status=&lic_hp=&ws=857&license_search=Search`;
  console.log("TREC Agent License Link:", trecAgentLicenseLink);

  const trecBrokerLink = `https://www.trec.texas.gov/apps/license-holder-search/?lic_name=${broker_keywords}&industry=Real+Estate&county=&display_status=&lic_hp=&ws=857&license_search=Search`;
  console.log("TREC Link:", trecAgentLink);

  return (
    <>
      <ContactDetailsRow>
        <Col xs={8}>
          <div>
            {listing?.list_agent_full_name &&
              `AGENT`}
          </div>
          <div style={{ fontWeight: 600 }}>
            {listing?.list_agent_full_name &&
              listing?.list_agent_full_name}
            {listing?.list_agent_mls_id &&
              <a
                href={trecAgentLink}
                title={listing?.list_agent_mls_id ? `TREC License #${listing.list_agent_mls_id}` : "TREC License # not found"}
                target="_blank"
                style={{ fontSize: 12 }}
              > (license)</a>}
          </div>
          {listing?.list_agent_direct_phone && (
            <a href={`tel:${listing?.list_agent_direct_phone}`}>
              {listing?.list_agent_direct_phone}
            </a>
          )}
        </Col>
        <Col id="agentActions" style={{ padding: 0 }} xs={4}>

          {listing?.list_agent_full_name && (




            <a
              href={linkedInUrl}
              target="_blank"
              rel="noopener noreferrer"
              style={{ marginRight: 10 }}>
              <Linkedin />
            </a>
          )}

          {listing?.list_agent_email && (
            <a
              href={"mailto:" + listing.list_agent_email}
              style={{ marginRight: 10 }}
            >
              <Mail />
            </a>
          )}
          {listing?.list_agent_direct_phone && (
            <a href={"sms:" + listing.list_agent_direct_phone}>
              <MessageCircle />
            </a>
          )}
        </Col>
      </ContactDetailsRow>

      {listing.list_office_name && (
        <ContactDetailsRow>
          <Col xs={12}>
            <div>BROKER</div>
            <div style={{ fontWeight: 600 }}> {listing.list_office_name}</div>
            {listing?.list_office_phone && (
              <a href={`tel:${listing?.list_office_phone}`}>
                {listing?.list_office_phone}
              </a>
            )}
          </Col>
        </ContactDetailsRow>
      )}
      {listing.data_source !== "CORELOGIC" && (
        <ContactDetailsRow>
          <Col xs={12}>
            <div>SOURCE</div>
            <span>MLS#: {listing.listing_id} via {listing.data_source}</span>
          </Col>
        </ContactDetailsRow>
      )}
      {listing.data_source === "CORELOGIC" && "Property is unlisted."}
    </>
  );
}
