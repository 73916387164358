import { ContactDetails, ExternalLinks } from "../../components/listing";
import { Estimation, Listing } from "../../../api/interfaces/listing";
import { RentomaticFooter } from "./components";
import { PageSubTitle } from "../../components/layout/PageSubtitle";
import { DossierWidget, ProformaWidget } from "../../components/widgets";

interface Props {
  listing?: Listing;
  estimation: Estimation | null;
}

export const RentomaticSidebar = (props: Props) => {
  const { estimation, listing } = props;
  const estimatedReturn = estimation?.rent_prediction === 0 ? 0 : estimation?.return_prediction;

  return (
    <div>
      <PageSubTitle title="Listing Contact Info"></PageSubTitle>
      <div className='mb-2'>
        <div className={`mb-3 listing_contact_web`}>
          <ContactDetails listing={props.listing} />
        </div>
      </div>

      {props.listing && props.listing.property_type !== "Residential Lease" && (
        <div id="next-steps">

          <PageSubTitle title="Investment Analysis"></PageSubTitle>
          <ProformaWidget
            className='mb-3 withBoxShadow'
            estimatedReturn={estimatedReturn}
            listingUrl={`/proforma/${props.listing.listing_key}`}
          />

          <PageSubTitle title="Ownership & Disclosures"></PageSubTitle>
          <DossierWidget
            className='mb-3 withBoxShadow'
            estimatedReturn={props.estimation?.return_prediction}
            listingKey={props.listing.listing_key}
          />

          <PageSubTitle title="External Links"></PageSubTitle>
          <ExternalLinks enableMls listing={props.listing} />

        </div>
      )}
      <RentomaticFooter />
    </div>
  );
};
