import React, { useState, useEffect } from "react";

import { useApi } from "../../../api/useApi";
import { LoadingIndicator } from "../../components/LoadingIndicator";
import { PageSubTitle } from "../../components/layout/PageSubtitle";
import { useNavigate } from "react-router-dom";

export const FinishSubscription = () => {
  const apiContext = useApi();
  const navigate = useNavigate();
  const [status, setStatus] = useState('checking');

  useEffect(() => {
    setTimeout(() => {
      setStatus("complete")
    }, 10000)
  }, [apiContext]);

  if (status === "complete") {
    navigate("/dashboard/checkout");
  }

  return (
    <>
      {status === "checking" && (
        <>
          <PageSubTitle title="We're fetching your purchase status"></PageSubTitle>
          <LoadingIndicator />
          &nbsp; Loading...
        </>
      )}
    </>
  );
};
