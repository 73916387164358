import { Col, Image, Row } from "react-bootstrap";
import styled from "styled-components";
import { CTABtn } from "./CTABtn";
import { USDollar } from "../../utils";

const Wrapper = styled.div`
  // background: #fff;
  border-radius: 8px;
  border: 2px solid #000;
  padding: 12px;
  color: #000;
`;

const ImageCol = styled(Col)`
  flex: 0 0 85px;
`;

const BtnCol = styled(Col)`
  align-content: space-evenly;
`;

const EstReturn = styled.div`
  font-weight: bold;
  font-size: 26px;
  line-height: 26px;
  color: #000;

  & div:nth-child(2n) {
    font-size: 12px;
    font-weight: normal;
    margin-left: 5px;
  }
`;

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  listingUrl?: string | null;
  estimatedRent?: number;
}

export const RentomaticWidget = (props: Props) => {
  return (
    <Wrapper className={props.className}>
      <Row>
        <ImageCol>
          <Image
            rounded
            src='/images/module_icons/rent-o-matic.png'
            width={85}
          />
        </ImageCol>
        <BtnCol>
          <EstReturn className='mb-2'>
            <div className="fleft">
              {props.estimatedRent && USDollar.format(props.estimatedRent)}
            </div>
            <div  className="fleft">Estimated Rent</div>
            <div className="clear"></div>
          </EstReturn>
         {props.listingUrl &&  <CTABtn enabled url={props.listingUrl} title='OPEN RENTOMATIC' />}
        </BtnCol>
      </Row>
    </Wrapper>
  );
};
