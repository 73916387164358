import styled from "styled-components";
import { useEffect, useState } from "react";
import { Image, ProgressBar } from "react-bootstrap";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import { useApi } from "../../api/useApi";
import { SearchTypeAhead } from "./SearchTypeAhead";
import { ChartWidget } from "../components/widgets/ChartWidget";


const Recent = styled.div`
  width: 500px;
  font-size: 16px;
  text-align: center;
  position: relative;
  padding-top: 60px;

  button {
    border: none;
    background: none;
    font-size: 14px;
    padding-left: 0;
    padding-right: 0;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
  }
`

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 100%;
`;

const Logo = styled.div`
  width: 200px;
  margin: 0px 0px;
  img {
    width: 100%;
  }
`;

const DivCenter = styled.div`
  width: 60%;
`;


export const SearchPage = () => {
  const navigate = useNavigate();
  const apiContext = useApi();
  const [isLoading, setIsLoading] = useState(false);
  const [progress, setProgress] = useState(0)
  const [statusMessage, setStatusMessage] = useState<string>("");
  const [options, setOptions] = useState<any[]>([]);
  const [searchParams] = useSearchParams();

  const viewProperty = (propertyId: string, address: string) => {
    navigate(`/rentomatic/${propertyId}`);
  };

  const toTitleCase = (str: string): string => {
    return str
      .toLowerCase()
      .split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  };

  useEffect(() => {
    if (isLoading) {
      setTimeout(() => {
        setProgress(progress + 1)
      }, 100)
    } else {
      setProgress(0)
    }
  }, [isLoading, progress])

  const getUserHistory = async () => {
    const results = await apiContext?.getSearchHistory();
    results.reverse().length = Math.min(results.length, 5);
    setOptions(results);
  };

  useEffect(() => {
    getUserHistory()
  }, [])

  return (
    <Wrapper className='mt-3' id='main-column'>
      <Helmet>
        <title>Self Realty - Search</title>
        <meta name="description" content={`Enter an address or paste a listing url.`} />
        <meta property="og:title" content={`Self Realty - Search`} />
        <meta property="og:description" content={`Enter an address or paste a listing url.`} />
        <link rel="image_src" href="/images/self-logo-og.png" />
        <meta property="og:image" content="/images/self-logo-og.png" />
        <meta name="twitter:image:src" content="/images/self-logo-og.png" />
      </Helmet>
      <Logo className='mb-3' id='logo-image'>
        <Image src='/images/horizontal-logo.png' />
      </Logo>
      <SearchTypeAhead
        setIsLoading={setIsLoading}
        setStatusMessage={setStatusMessage}
        onChange={viewProperty}
        isLoading={isLoading}
        value={searchParams.get("input") ?? undefined}
      />
      {isLoading && (
        <DivCenter className='mt-5'>
          <ProgressBar animated now={progress} />
          <div className='alignCenter mt-2'>{statusMessage}</div>
        </DivCenter>
      )}
      {options.length > 0 && (
        <>
          <Recent className="mt-3" id="recent-searches">
            <div className="mb-1 mt-3">
              <b>Recent Searches</b>
            </div>
            {options.map((x) => (
              <div key={x.clip}>
                <button onClick={() => viewProperty(x.clip, x.address)}>
                  {toTitleCase(x.address)}
                </button>
              </div>
            ))}
          </Recent>
        </>
      )}

      <ChartWidget />

    </Wrapper>

  );
};
