import { useState } from "react";

import { useApi } from "api/useApi";
import { Proforma as ProformaType } from "api/interfaces/proforma";

import { useNavigate, useParams } from "react-router-dom";
import hash from "object-hash";
import { Proforma } from "./Proforma";
import { useListing } from "../../hooks/useListing";
import { Listing, ListingCompactView } from "../../../api/interfaces/listing";
import { ContentWithSideBar } from "../../components/layout";
import { useModules } from "../../hooks/useModules";
import { useLoadListing } from "ui/hooks/useLoadListing";
import { handleListingLoadError } from "../errorHandlers";

export const ProformaWrapper = () => {
  const { listingString } = useParams();
  const apiContext = useApi();
  const modulesContext= useModules()
  const listingContext = useListing();
  const navigate = useNavigate();
  const [proforma, setProforma] = useState<ProformaType | null>(null);
  const [comparables, setComparables] = useState<ListingCompactView[]>([]);
  const [hashKey, setHash] = useState<string>("");


  const handleLoadProforma = async (listing: Listing): Promise<void> => {
    try {
      const proforma = await apiContext?.getProforma(listing);
      if (proforma) {
        setProforma(proforma);
      }
    } catch (err) {
      // @ts-ignore
      // if (error.status === 403)(
      //   navigate('/dashboard')
      // )
      console.error(err);
      return;
    }
    try {
      const rentomatic = await apiContext?.getRentomatic(listing, []);
      if (rentomatic) {
	setComparables(rentomatic.comparables);
      }
    } catch(err) {
      console.error(err)
    }
  };

  const onSave = async (proforma: ProformaType): Promise<void> => {
    // setInitialLoading(true);
    // const newProforma = await apiContext?.saveProforma(listingKey!, proforma);
    // if (newProforma) {
    //   setProforma(newProforma);
    // }
    // setInitialLoading(false);
  };

  const onDelete = async (): Promise<void> => {
    // setInitialLoading(true);
    // await apiContext?.deleteProforma(listingKey!);
    // // await handleLoadProforma();
    // setInitialLoading(false);
  };

  const onReset = () => {
    setProforma(proforma);
    setHash(
      hash({
        time: new Date().valueOf(),
      })
    );
  };

  const { listingLoading, loadError } = useLoadListing(listingString, listingContext, apiContext, handleLoadProforma);

  if (loadError) {
    return handleListingLoadError(loadError, navigate);
  }
  
  if (listingLoading || modulesContext?.loading || !listingContext?.listing) {
    return <ContentWithSideBar loading />;
  }
  //
  // if (!modulesContext?.hasProformanator()) {
  //   navigate(`/dossier`);
  //   return null;
  // }

  return (
    <>
      <Proforma
        key={hashKey}
        proforma={proforma!}
        comparables={comparables}
        listing={listingContext?.listing!}
        onSave={onSave}
        onReset={onReset}
        onDelete={onDelete}
      />
    </>
  );
};
