import styled from "styled-components";

interface Props extends React.ComponentPropsWithoutRef<"div"> {
  title: string;
  button?: React.ReactNode | null;
}

const Title = styled.div`
  color: #000;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
`;

const Line = styled.div`
  width: 100%;
  height: 2px;
  background: #000;
`;

const RowContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Buttons = styled.div`
  display: flex;
  align-items: center;
`;

// export const PageSubTitle = (props: Props) => {
//   return (
//     <div className='mb-3 mt-2' id="sidebar-header">
//       <RowContainer>
//         <Title>{props.children}</Title>
//         <Buttons>
//           {/* optional. eg City buttons */}
//         </Buttons>
//       </RowContainer>
//       <div className="clear"></div>
//       <Line></Line>
//     </div>
//   );
// };

export const PageSubTitle = ({ title, button }: Props) => {
  return (
    <div className='mb-3 mt-2' id="sidebar-header">
      <RowContainer>
        <Title>{title}</Title>
        <Buttons>
          {/* optional. eg City buttons */}
          {button}
        </Buttons>
      </RowContainer>
      <div className="clear"></div>
      <Line></Line>
    </div>
  );
};