import { Image } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import { Server, Heart, Sliders, Clock, DollarSign, CreditCard } from "react-feather";
import styled from "styled-components";
import { PageSubTitle } from "../../components/layout/PageSubtitle";
import { TipsCarousel } from "../../components/widgets/TipsCarousel";

const Menu = styled.div`
  margin-left: 10px;
`;

const MenuLink = styled.div`
  a {
    text-decoration: none;
    color: #000;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px; /* 177.778% */

    &.active span,
    &:hover span {
      text-decoration: underline;
    }

    span {
      margin-left: 5px;
    }
  }
`;

const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: 20px;
  width: 100%;
`;




export const SidebarMenu = () => {
  const location = useLocation();
  const isActive = (url: string) => {
    return location.pathname.startsWith(url);
  };
  return (
    <>
      <Menu>
        <MenuLink className='mb-1'>
          <Link
            to={"/dashboard"}
            className={isActive("/dashboard") ? "active" : ""}
            title='Services'
          >
            <Server /> <span>Services</span>
          </Link>
        </MenuLink>
        <MenuLink className='mb-1'>
          <Link
            to={"/favorites"}
            className={isActive("/favorites") ? "active" : ""}
            title='Favorites'
          >
            <Heart /> <span>Favorites</span>
          </Link>
        </MenuLink>
        <MenuLink className='mb-1'>
          <Link
            to={"/accounts/preferences"}
            className={isActive("/accounts/preferences") ? "active" : ""}
            title='Preferences'
          >
            <Sliders /> <span>Preferences</span>
          </Link>
        </MenuLink>
        {/* <MenuLink className='mb-1'>
          <Link
            to={"/history"}
            className={isActive("/history") ? "active" : ""}
            title='History'
          >
            <Clock /> <span>History</span>
          </Link>
        </MenuLink> */}
        <MenuLink className='mb-1'>
          <Link
            to={"/dashboard/checkout"}
            className={isActive("/dashboard/checkout") ? "active" : ""}
            title='Billing'
          >
            <CreditCard /> <span>Billing</span>
          </Link>
        </MenuLink>
      </Menu>

      {/* {authContext? === false &&
        !location.pathname.startsWith("/accounts/profile") && (
          <div className='mt-3'>
            <PageSubTitle>Upgrade to SELF Realty PLUS</PageSubTitle>{" "}
            <PlanManagement />
          </div>
        )} */}

      <div className='mt-3'>
        <PageSubTitle title="TIPS"></PageSubTitle>
        {/* <p>
          <b>
            Drag this link:         <a href="javascript:(function(){var%20currentUrl=encodeURIComponent(window.location.href);window.open('https://self.realty/'+currentUrl,'_blank');})();">
              Self.Realty
            </a> into your bookmarks bar for one-click access to Self Realty services while browsing homes online.
          </b>
        </p>
        <p>
          <a href="/images/bookmarklet1.jpg"><img width='100%' src='/images/bookmarklet1.jpg' alt='bookmarklet' /></a>
        </p> */}
     
        <TipsCarousel></TipsCarousel>
     
      </div>
      <ImageWrapper>
        <Image width={200} src='/images/horizontal-logo.png' />
      </ImageWrapper>
    </>
  );
};
