import { useEffect, useState } from "react";
import { Listing } from "api/interfaces/listing";
import type { ListingContextType } from "./useListing";
import type { ApiContextType } from "api/useApi";
import { NotFoundError } from "common/exceptions";
import { useLocation } from "react-router-dom";


type UseLoadListingResult = {
  listingLoading: boolean;
  loadError: Error | null;
};


const fetchListing = async (listingString: string, apiContext: ApiContextType): Promise<any> => {
  try {
    return await apiContext.getListing(listingString);
  } catch (error) {
    if (error instanceof NotFoundError) {
      console.debug("Listing not found. Falling back to metadata");
      const metadataResponse = await apiContext.getPropertyMetadata(listingString);
      if (metadataResponse) {
	return metadataResponse;
      }
    }
    throw error;
  }
}


export function useLoadListing(
  listingString: string | undefined,
  listingContext: ListingContextType,
  apiContext: ApiContextType,
  onLoaded?: (listing: Listing) => Promise<void>
): UseLoadListingResult {
  const [listingLoading, setListingLoading] = useState<boolean>(true);
  const [loadError, setLoadError] = useState<Error | null>(null);
  const location = useLocation();

  const setError = (error: unknown) => {
    if (error instanceof Error) {
      setLoadError(error);
    } else {
      console.error(error);
      setLoadError(new Error("Unknown error"));
    }
  };

  useEffect(() => {
    console.debug("listingString", listingString);

    if (!listingString) {
      console.warn("No listing string");
      return;
    }

    if (listingString === listingContext?.listingKey) {
      console.debug("Listing already loaded");
      if (onLoaded) {
	onLoaded(listingContext?.listing!).catch((error) => {
	  console.error(error);
	  setError(error);
	}).finally(() =>{
	  setListingLoading(false);
	})
      }
      return;
    }

    fetchListing(listingString, apiContext)
      .then((data) => {
	if (data) {
	  if (onLoaded) {
	    return Promise.resolve(onLoaded(data))
	      .then(() => {
		listingContext.setListing(data);
		listingContext.setListingKey(data.listing_key);
		return data;
	      });
	  } else {
	    listingContext.setListing(data);
	    listingContext.setListingKey(data.listing_key);
	    return data;
	  }
	}
      })
      .catch((error) => {
	setError(error);
      })
      .finally(() => {
	setListingLoading(false);
      });
  }, [listingString, location]);

  return { listingLoading, loadError };
}
