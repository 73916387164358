import exp from "constants";
import React, { useRef, useEffect, useState } from "react";
import styled from "styled-components";

const GaugeContainer = styled.div`
  width: 75%;
  height: 170px;
  background: #1a233d;
  border-radius: 8px;
  border: 1px solid #6a76a0;
  box-shadow: 0px 5.393px 26.965px 6.741px rgba(0, 0, 0, 0.08);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  margin-right: 1%;
`;

const VacancyContainer = styled.div`
  width: 24%;
  height: 170px;
  background: #1a233d;
  border-radius: 8px;
  border: 1px solid #6a76a0;
  box-shadow: 0px 5.393px 26.965px 6.741px rgba(0, 0, 0, 0.08);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const PlaceholderLabel = styled.div`
  position: absolute;
  left: 10px;
  color: white;
  opacity: 0.25;
  font-size: 20px;
  font-weight: bold;
`;

const SVGContainer = styled.svg`
  width: 100%;
  height: 100%;
  font-weight: bold;
`;

interface LinearGaugeProps {
    expectedRent: number | null;
    actualRent: number | null;
    vacancyDays: number | null;
    expectedRentPsf: number | null;
    actualRentPsf: number | null;
}

export const LinearGauge: React.FC<LinearGaugeProps> = ({
    expectedRent,
    actualRent,
    vacancyDays,
    expectedRentPsf,
    actualRentPsf,
}) => {
    const pointerSize = 15;
    const lineThickness = 10;
    const lineYPosition = 85;
    const lineWidthPercentage = 0.95;
    const marginPercentage = (1 - lineWidthPercentage) / 2;

    const svgRef = useRef<SVGSVGElement | null>(null);
    const [svgWidth, setSvgWidth] = useState(0);

    useEffect(() => {
        const updateWidth = () => {
            if (svgRef.current) {
                setSvgWidth(svgRef.current.clientWidth);
            }
        };
        updateWidth();
        window.addEventListener("resize", updateWidth);
        return () => window.removeEventListener("resize", updateWidth);
    }, []);

    const expectedRentPosition = svgWidth / 2;

    const isCentered = (expectedRent ?? 0) === 0 || (actualRent ?? 0) === 0;

    const scale = (value: number) => {
        if (isCentered) return expectedRentPosition;

        const minActualRent = ((expectedRent ?? actualRent ?? 1) as number) * 0.5;
        const maxActualRent = ((expectedRent ?? actualRent ?? 1) as number) * 1.5;
        return (
            marginPercentage * svgWidth +
            ((value - minActualRent) / (maxActualRent - minActualRent)) *
            (svgWidth * lineWidthPercentage)
        );
    };

    const getGradientColor = (value: number) => {
        if (isCentered) return "yellow";

        const minActualRent = (expectedRent ?? 1) * 0.5;
        const maxActualRent = (expectedRent ?? 1) * 1.5;
        const ratio = (value - minActualRent) / (maxActualRent - minActualRent);

        if (ratio <= 0.5) {
            const green = Math.round(255 - ratio * 2 * 255);
            return `rgb(${255 - green}, 255, 0)`;
        } else {
            const red = Math.round(255 * ((ratio - 0.5) * 2));
            return `rgb(255, ${255 - red}, 0)`;
        }
    };

    return (
        <div
            id="linear_gauge_wrapper"
            style={{
                display: "flex",
                alignItems: "center",
                margin: "0px",
                justifyContent: "flex-start",
                padding: "0px",
            }}
        >
            <GaugeContainer id="rent_gauge">
                {/* {expectedRentPsf !== null && (
                    <PlaceholderLabel style={{ top: "25px" }}>
                        ${expectedRentPsf} ft
                    </PlaceholderLabel>
                )}
                {actualRentPsf !== null && (
                    <PlaceholderLabel style={{ bottom: "25px" }}>
                        ${actualRentPsf} ft
                    </PlaceholderLabel>
                )} */}

                {expectedRentPsf !== null && expectedRentPsf !== 0 && (
                    <PlaceholderLabel style={{ top: "25px" }}>
                        ${expectedRentPsf} ft
                    </PlaceholderLabel>
                )}
                {actualRentPsf !== null && actualRentPsf !== 0 && (
                    <PlaceholderLabel style={{ bottom: "25px" }}>
                        ${actualRentPsf} ft
                    </PlaceholderLabel>
                )}
                <SVGContainer ref={svgRef}>
                    <defs>
                        <linearGradient id="rentGradient" x1="0%" y1="50%" x2="100%" y2="50%">
                            <stop offset="0%" style={{ stopColor: "green", stopOpacity: 1 }} />
                            <stop offset="50%" style={{ stopColor: "yellow", stopOpacity: 1 }} />
                            <stop offset="100%" style={{ stopColor: "red", stopOpacity: 1 }} />
                        </linearGradient>
                    </defs>

                    <rect
                        x={`${marginPercentage * 100}%`}
                        y={lineYPosition - lineThickness / 2}
                        width={`${lineWidthPercentage * 100}%`}
                        height={lineThickness}
                        fill="url(#rentGradient)"
                        rx="5"
                        ry="5"
                    />

                    {/* Expected Rent Pointer (Always Centered if Required) */}
                    {expectedRent !== 0 && expectedRent !== null && (
                        <polygon
                            id="expected_rent_pointer"
                            points={`
              ${expectedRentPosition - pointerSize / 2},${lineYPosition - lineThickness / 2}
              ${expectedRentPosition + pointerSize / 2},${lineYPosition - lineThickness / 2}
              ${expectedRentPosition},${lineYPosition - pointerSize - lineThickness / 2}
            `}
                            fill="yellow"
                        />
                    )}

                    {/* Actual Rent Pointer (Matches Gradient or Centers if Required) */}
                    {actualRent !== 0 && actualRent !== null && (
                        <polygon
                            id="actual_rent_pointer"
                            points={`
              ${scale(actualRent ?? 0) - pointerSize / 2},${lineYPosition + lineThickness / 2}
              ${scale(actualRent ?? 0) + pointerSize / 2},${lineYPosition + lineThickness / 2}
              ${scale(actualRent ?? 0)},${lineYPosition + pointerSize + lineThickness / 2}
            `}
                            fill={getGradientColor(actualRent ?? 0)}
                        />
                    )}
                    {expectedRent !== 0 && expectedRent !== null && (
                        <text
                            x={expectedRentPosition}
                            y={lineYPosition - pointerSize - lineThickness - 40}
                            fontSize="12"
                            fill="white"
                            fontWeight="lighter"
                            textAnchor="middle"
                        >
                            Expected Rent
                        </text>
                    )}
                    {expectedRent !== 0 && expectedRent !== null && (
                        <text
                            x={expectedRentPosition}
                            y={lineYPosition - pointerSize - lineThickness - 5}
                            fontSize="35"
                            fill="white"
                            fontWeight="bold"
                            textAnchor="middle"
                        >
                            ${expectedRent?.toLocaleString() ?? "0"}
                        </text>
                    )}

                    {actualRent !== 0 && actualRent !== null && (
                        <text
                            x={scale(actualRent ?? 0)}
                            y={lineYPosition + pointerSize + lineThickness + 25}
                            fontSize="35"
                            fill="white"
                            fontWeight="bold"
                            textAnchor="middle"
                        >
                            ${actualRent?.toLocaleString() ?? "0"}
                        </text>
                    )}
                    {actualRent !== 0 && actualRent !== null && (
                        <text
                            x={scale(actualRent ?? 0)}
                            y={lineYPosition + pointerSize + lineThickness + 45}
                            fontSize="12"
                            fill="white"
                            fontWeight="lighter"
                            textAnchor="middle"
                        >
                            Listed Rent
                        </text>
                    )}

                </SVGContainer>
            </GaugeContainer>
            <VacancyContainer id="vacancy_gauge">
                <div style={{ fontSize: "35px", fontWeight: "bold", color: "white", lineHeight: "1" }}>
                    {vacancyDays ?? 0}
                </div>
                <div style={{ fontSize: "12px", fontWeight: "normal", color: "white", marginTop: "5px" }}>
                    Expected Days of Vacancy
                </div>
            </VacancyContainer>
        </div>
    );
};

export default LinearGauge;
