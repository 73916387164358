import { useApi } from "api/useApi";
import {
  Estimation,
  Listing,
  ListingCompactView,
} from "../../../api/interfaces/listing";
import { useState } from "react";
import { Rentomatic } from "./Rentomatic";
import { useListing } from "../../hooks/useListing";
import { ContentWithSideBar } from "../../components/layout";
import { RentomaticSidebar } from "./RentomaticSidebar";
import { useNavigate, useParams } from "react-router-dom";
import { useModules } from "../../hooks/useModules";
import { useLoadListing } from "ui/hooks/useLoadListing";
import { handleListingLoadError } from "../errorHandlers";

export const RentomaticWrapper = () => {
  const apiContext = useApi();
  const listingContext = useListing();
  const modulesContext = useModules();
  const navigate = useNavigate();
  const [comparables, setComparables] = useState<ListingCompactView[]>([]);
  const [estimation, setEstimation] = useState<Estimation | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  const { listingString } = useParams();

  const handleLoadComparables = async (
    listing: Listing,
    comparables_ids: string[] = []
  ): Promise<void> => {
    setLoading(true);
    try {
      const data = await apiContext?.getRentomatic(listing, comparables_ids);
      if (data) {
        setComparables(data.comparables);
        setEstimation(data.estimation);
      }
    } finally {
      setLoading(false);
    }
  };

  const handleComparablesUpdate = async (comparablesIds: string[]): Promise<void> => {
    await handleLoadComparables(listingContext?.listing!, comparablesIds);
  };

  const { listingLoading, loadError } = useLoadListing(listingString, listingContext, apiContext, handleLoadComparables);

  if (loadError) {
    return handleListingLoadError(loadError, navigate);
  }

  if (listingLoading || modulesContext?.loading || !listingContext?.listing) {
    return <ContentWithSideBar loading />;
  };

  return (
    <ContentWithSideBar
      center={
        <Rentomatic
          loading={loading}
          listing={listingContext?.listing!}
          handleComparablesUpdate={handleComparablesUpdate}
          comparables={comparables}
          estimation={estimation}
        />
      }
      sidebar={
        <RentomaticSidebar
          listing={listingContext?.listing!}
          estimation={estimation}
        />
      }
    />
  );
};
