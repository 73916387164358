import { Form } from "react-bootstrap";
import { PriceInput } from "./PriceInput";
import { useEffect, useState } from "react";

export interface RowProps {
  key: string;
  id: string | null;
  label: React.ReactNode;
  value: number | null;
  onUpdate: (val: number | null) => void;
  getDefaultValue: () => number
}

export const OperatingIncomeRow = (props: RowProps) => {
  const [enableRow, setEnableRow] = useState<boolean>(props.value !== null);
  const [monthlyValue, setMonthlyValue] = useState<number | null>(props.value);

  useEffect(() => {
    if (!enableRow) {
      setMonthlyValue(null);
    } else {
      setMonthlyValue(props.getDefaultValue());
    }
  }, [enableRow]);

  useEffect(() => {
    if (enableRow) {
      setMonthlyValue(props.value);
    }
  }, [props.value]);

  useEffect(() => {
    props.onUpdate(monthlyValue);
  }, [monthlyValue]);

  return (
    <tr>
      <td className='w45' colSpan={2}>
        {props.label}
      </td>
      <td className='w15' align='center'>
        <Form.Check
          type='switch'
          defaultChecked={enableRow}
          onChange={(e) => setEnableRow(e.target.checked)}
          id={`${props.id}Switch`}
        />
      </td>
      <td align='right' className='w20'>
        <PriceInput
          value={monthlyValue ?? 0}
          onUpdate={setMonthlyValue}
          id={`${props.id}Monthly`} />
      </td>
      <td align='right' className='w20'>
        <PriceInput
          value={(monthlyValue ?? 0) * 12}
          onUpdate={(val) => setMonthlyValue(val / 12)}
          id={`${props.id}Yearly`}
        />
      </td>
    </tr>
  );
};
