import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

import Table from "react-bootstrap/Table";
import { PercentDecimalDouble, USDollar } from "../../utils";

import { PageSubTitle } from "../../components/layout/PageSubtitle";
import { ListingCompactView } from "../../../api/interfaces/listing";
import { useApi } from "../../../api/useApi";
import { LoadingIndicator } from "../LoadingIndicator";
import { ListingCard } from "../listing";


const ChartWidgetWrapper = styled.div`
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 30px;
`;

const TableWrapper = styled.div`
//   height: calc(97vh - 374px);
//   overflow-y: scroll;
    position: relative;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 10px;
`;

const StyledTable = styled.div`
  padding: 10px;
  border: 1px solid #ebebfb;
  font-size: 14px;

  .header-row, .row {
    display: flex;
    align-items: center;
    // background-color: #ebebfb;
    width: 100%;
  }

  .header {
    font-weight: bold;
    padding: 5px;
    text-align: center;
    flex: 1;
  }

  .header-address {
    font-weight: bold;
    padding: 5px;
    text-align: left;
    flex: 3;
  }

  .results-row {
        display: flex;
        flex-direction: column;
    }

  .cell {
    padding: 5px;
    text-align: center;
    flex: 1;
  }

  .cell-address {
    text-align: left;
    flex: 3;
  }

  .row:hover {
    background-color: yellow;
  }

  .link {
    text-decoration: none;
    color: inherit;
  }

  .link:hover {
    text-decoration: underline;
  }
`;

const LoadingNotice = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;

const CityLinks = styled.div`

  a {
    cursor: pointer;
    margin: 0 10px;
    text-decoration: none;
    color: #000;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;

    &.selected {
    text-decoration: underline;
    }

    &:hover {
      text-decoration: underline;
    }
  }
`;

interface ChartWidgetProps {
    // city: string | null;
}



export const ChartWidget: React.FC<ChartWidgetProps> = () => {
    const apiContext = useApi();
    const [loading, setLoading] = useState<boolean>(true);
    const [listings, setListings] = useState<ListingCompactView[]>([]);
    const [selectedCity, setSelectedCity] = useState<string>('Austin');



    useEffect(() => {
        const fetchData = async () => {
            const data = await apiContext?.getTopPicks(selectedCity)

            try {
                // setChartData(data);
                setLoading(false);
                setListings(data);
            } catch (error) {
                console.error("Error fetching data: ", error);
            }
        };

        fetchData();
    }, [selectedCity]);

    const handleCityClick = (city: string) => {
        setLoading(true);
        setSelectedCity(city);
    };

    // if (loading) {
    //     return <LoadingNotice>Loading Top Charts...</LoadingNotice>;
    // }

    return (


        // <div className="mt-3">
        //     {listings.map((x) => (
        //         <div className='mb-3 chart' key={x.listing_key}>
        //             <ListingCard className="withBoxShadow" listing={x} />
        //         </div>
        //     ))}
        // </div>
        <ChartWidgetWrapper id="chart-widget">
            <PageSubTitle title="Top Charts"
                button={
                    <>
                        <CityLinks>
                            <a
                                onClick={() => handleCityClick('Austin')}
                                className={selectedCity === 'Austin' ? 'selected' : ''}
                            >
                                Austin
                            </a> |
                            <a
                                onClick={() => handleCityClick('Dallas')}
                                className={selectedCity === 'Dallas' ? 'selected' : ''}
                            >
                                Dallas
                            </a> |
                            <a
                                onClick={() => handleCityClick('Houston')}
                                className={selectedCity === 'Houston' ? 'selected' : ''}
                            >
                                Houston
                            </a>
                        </CityLinks>
                    </>}>

            </PageSubTitle>
            <TableWrapper>
                {loading ?
                    (<LoadingNotice>Loading {selectedCity} Charts...</LoadingNotice>) :
                    <>
                        {listings.length > 0 && (
                            <>
                                <StyledTable>
                                    <div className="header-row">
                                        <div className="header-address">Address</div>
                                        <div className="header">Beds</div>
                                        <div className="header">Year Built</div>
                                        <div className="header">Price</div>
                                        <div className="header">Rent Est</div>
                                        <div className="header"><u>▼ Return Est</u></div>
                                    </div>

                                    <div className="results-row">
                                        {listings.map((listing) => (
                                            <div className="row" key={listing.listing_key}>
                                                <div className="cell-address"><Link to={`/rentomatic/${listing.listing_key}`}>{listing.full_address}</Link></div>
                                                <div className="cell">{listing.bedrooms_total}</div>
                                                <div className="cell">{listing.year_built}</div>
                                                <div className="cell">{USDollar.format(listing.list_price)}</div>
                                                <div className="cell">{listing.estimation?.rent_prediction
                                                    ? USDollar.format(listing.estimation?.rent_prediction)
                                                    : null}</div>
                                                <div className="cell">{listing.estimation?.return_prediction
                                                    ? PercentDecimalDouble.format(listing.estimation.return_prediction) + "%"
                                                    : null}</div>
                                            </div>
                                        ))}
                                    </div>
                                </StyledTable>
                            </>
                        )
                        }
                    </>}
            </TableWrapper >
        </ChartWidgetWrapper >
    );
};
