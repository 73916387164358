import { useEffect, useState } from "react";
import { Dropdown, Image } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import {
  User,
  LogOut,
  Heart,
  Server,
  Sliders,
  Clock,
} from "react-feather";
import { useApi } from "../../../api/useApi";
import styled from "styled-components";
import { SubjectListing } from "../SubjectListing";
import { useAuth } from "../../hooks/useAuth";

const NavigationWrapper = styled.div`
  top: 0;
  left: 0;
  width: 100%;
  height: 40px;
  z-index: 900;
  position: absolute;
  border-bottom: 2px solid #000;
`;

const Logo = styled.div`
  height: 40px;
  width: 80px;
  position: absolute;
  text-align: center;
  border-bottom: 2px solid #000;

  img {
    width: 120px;
    padding-top: 6px;
    padding-left: 10px;
  }
`;

export const Navigation = () => {
  const authContext = useAuth();
  const navigate = useNavigate();
  const context = useApi();
  const [user, setUser] = useState<string | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await context?.getUserData();
        if (data) {
          setUser(
            `${data?.first_name} ${data?.last_name ? data?.last_name[0] : ""}`
          );
          authContext?.setUser(data);
        }
      } catch (error) {
        console.log(error);
      }
    };

    fetchData().catch(console.error);
  }, []);

  if (user === null) {
    return null;
  }

  return (
    <NavigationWrapper id="navigation-header">
      <Logo id='logo'>
        <a href='https://selfrealty.com/?utm_source=app_nav'>
          <Image src='/images/horizontal-logo.png' />
        </a>
      </Logo>
      <SubjectListing />

      <div className='accountDropdown' id="account-dropdown">
        <Dropdown onSelect={(path) => navigate(path ?? "/")}>
          <Dropdown.Toggle>
            {user} <User />
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <Dropdown.Item eventKey='/accounts/profile'>
              Account <User />
            </Dropdown.Item>
            <Dropdown.Item eventKey='/favorites'>
              Favorites <Heart />
            </Dropdown.Item>
            <Dropdown.Item eventKey='/dashboard'>
              Services <Server />
            </Dropdown.Item>
            <Dropdown.Item eventKey='/accounts/preferences'>
              Preferences <Sliders />
            </Dropdown.Item>
            {/* <Dropdown.Item eventKey='/history'>
              History <Clock />
            </Dropdown.Item> */}
            <Dropdown.Item eventKey='/accounts/logout'>
              Logout <LogOut />
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </NavigationWrapper>
  );
};
