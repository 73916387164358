import { useState } from "react";
import { useApi, ApiError } from "../../../api/useApi";
import { Button, Form } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { LoadingIndicator } from "../../components/LoadingIndicator";

export const ResetPassword = () => {
  const navigate = useNavigate();
  const [token, setToken] = useState<any>();
  const [password, setPassword] = useState("");
  const [password_confirm, setPasswordConfirm] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState<Record<string, any>>({});
  const context = useApi();

  const handlePasswordReset = async (e: any) => {
    e.preventDefault();
    setErrors({});
    setIsLoading(true);
    try {
      await context?.resetPasswordConfirm(
        password,
        password_confirm,
        token
      );
      navigate("/accounts/login");
    } catch (error) {
      if (error instanceof ApiError) {
	setErrors(error.details || {error: "Token error"});
      } else {
	setErrors({ error: "Token error" });
      }
    }
    setIsLoading(false);
  };
  return (
    <>
      <p>
        Enter your new password, password confirmation and token from email.
      </p>
      <Form onSubmit={handlePasswordReset}>
        <Form.Group className='mb-3'>
          <Form.Label>Token</Form.Label>
          <Form.Control
            onChange={(e) => setToken(e.target.value)}
            type='text'
            required
            isInvalid={errors["error"]}
            placeholder='Enter token from email'
          />
          <Form.Control.Feedback type='invalid'>
            {errors["error"]}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group className='mb-3'>
          <Form.Label>New password</Form.Label>
          <Form.Control
            onChange={(e) => setPassword(e.target.value)}
            type='password'
            required
            isInvalid={errors["password"]}
            placeholder='New password'
          />
          <Form.Control.Feedback type='invalid'>
            {errors["password"] && errors["password"][0]}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group className='mb-3'>
          <Form.Label>New password confirmation</Form.Label>
          <Form.Control
            onChange={(e) => setPasswordConfirm(e.target.value)}
            type='password'
            required
            isInvalid={errors["password_confirm"]}
            placeholder='New password confirmation'
          />
          <Form.Control.Feedback type='invalid'>
            {errors["password_confirm"] && errors["password_confirm"][0]}
          </Form.Control.Feedback>
        </Form.Group>

        <Button variant='primary' type='submit' disabled={isLoading}>
          {isLoading ? (
            <>
              <LoadingIndicator />
              &nbsp; Loading...
            </>
          ) : (
            "Continue"
          )}
        </Button>
        <p className='mt-2 text-center'>
          Already have an account? <Link to='/accounts/login'>Login</Link>
        </p>
      </Form>
    </>
  );
};
