import { Link } from "react-router-dom";
import { PriceInput } from "./PriceInput";
import { ProformaTable } from "./ProformaTable";
import { InfoTooltip } from "../../../components/InfoTooltip";
import { USDollar } from "../../../utils";
import { Settings } from "react-feather";
import { styled } from "styled-components";

const SettingsLink = styled(Link)`
  color: #8c8c8c;
  text-decoration: none;
`;

const SettingsIcon = styled(Settings)`
  width: 18px;
  height: 18px;
  margin-right: 5px;
  stroke: blue;
`;

interface Props {
  piMonthly: number;
  setDownPayment: (val: number) => void;
  offerPrice: number;
  downPayment: number;
  downPaymentRate: number;
  loanTerm: number;
}

export const FinancingCosts = (props: Props) => {
  const { piMonthly } = props;

  return (
    <ProformaTable>
      <thead>
        <tr>
          <th className='w60'>
            Financing
          </th>
          <th className='w40'>
            <SettingsLink to="/accounts/preferences"><SettingsIcon /></SettingsLink>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            <InfoTooltip label='Cash portion of Offer Price'>Down Payment</InfoTooltip>
          </td>
          <td align='right'>
            <PriceInput
              value={props.downPayment}
              onUpdate={props.setDownPayment}
              id='downPaymentInput'
            />
          </td>
        </tr>
        <tr>
          <td>
            <InfoTooltip label='Difference between Offer Price and Down Payment'>Loan Amount</InfoTooltip>
          </td>
          <td align='right'>
            <div className='textOnly' id='loanAmount'>
              {USDollar.format(
                Math.max(props.offerPrice - props.downPayment, 0)
              )}
            </div>
          </td>
        </tr>
        <tr>
          <td>
            <InfoTooltip label='Length of mortgage in years'>Loan Term</InfoTooltip>
          </td>
          <td align='right'>
            <div className='textOnly' id='loanTerm'>{props.loanTerm}</div>
          </td>
        </tr>
        <tr>
          <td>
            <InfoTooltip label='Annual principal and interest payments'>P&I (annual)</InfoTooltip>
          </td>
          <td align='right'>
            <div className='textOnly' id='mortgageAnnual'>{USDollar.format(piMonthly * 12)}</div>
          </td>
        </tr>
        <tr>
          <td>
            <InfoTooltip label='Monthly principal and interest payments'>P&I (monthly)</InfoTooltip>
          </td>
          <td align='right'>
            <div className='textOnly' id='mortgageMonthly'>{USDollar.format(piMonthly)}</div>
          </td>
        </tr>
      </tbody>
    </ProformaTable>
  );
};
