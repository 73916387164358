import { NotFoundError } from "common/exceptions";
import { ThrottleError } from "api/useApi";
import { RateLimitError } from "ui/components/errors/RateLimit"

export const handleListingLoadError = (loadError: any, navigate: any): any => {
    if (loadError instanceof NotFoundError) {
      return navigate("/not_found");
    } else if (loadError instanceof ThrottleError) {
      console.warn(loadError);
      return RateLimitError({ errorMessage: loadError.message })
    }
};
