import React from "react";
import { Col, Row } from "react-bootstrap";

interface Props {
  errorMessage: string;
}

export const RateLimitError = (props: Props) => {
  return (
    <Row><Col className="mainCol error">
      <h3>
	Rate Limit Exceeded
      </h3>

      <p>{props.errorMessage}</p>
    </Col></Row>
  );
};
