import { Col, Row } from "react-bootstrap";
import styled from "styled-components";
import {
  Estimation,
  ListingCompactView,
} from "../../../api/interfaces/listing";
import { InfoIcon } from "../InfoIcon";
import { InfoTooltip } from "../../components/InfoTooltip";
import { findMedian } from "../../utils";
import { LinearGauge } from "../../components/LinearGauge";
import { ListingCard } from "./ListingCard";

interface Props {
  estimation: Estimation;
  comparables: ListingCompactView[];
  withEvaluations?: boolean;
  listing_price?: number;
  listing: ListingCompactView;
}

export function RentEstimations({
  estimation,
  withEvaluations,
  comparables,
  listing_price,
  listing,
}: Props) {

  let comparablesDaysOnMarket: number | undefined = findMedian(
    comparables.map((x) => x.days_on_market)
  );

  comparablesDaysOnMarket = Number.isNaN(comparablesDaysOnMarket)
    ? 0
    : comparablesDaysOnMarket;

  const vacancy_estimate = Math.floor(comparablesDaysOnMarket);

  const subject_sqft = listing.living_area
    ? parseFloat(listing.living_area.toString())
    : 0;

  const expected_rent = estimation.rent_prediction
    ? Math.floor(estimation.rent_prediction)
    : 0;

  const actual_rent = listing.property_type === "Residential Lease" && listing.list_price
    ? Math.floor(listing.list_price)
    : 0;

  const expected_rent_psf =
    (expected_rent && subject_sqft > 0
      ? parseFloat((Math.floor(expected_rent / subject_sqft * 100) / 100).toFixed(2))
      : 0);

  const actual_rent_psf =
    (actual_rent && subject_sqft > 0
      ? parseFloat((Math.floor(actual_rent / subject_sqft * 100) / 100).toFixed(2))
      : 0);

  console.log("Expected Rent:", expected_rent);
  console.log("Actual Rent:", actual_rent);
  console.log("Expected Rent PSF:", expected_rent_psf);
  console.log("Actual Rent PSF:", actual_rent_psf);
  console.log("Vacancy Estimate:", vacancy_estimate);
  console.log("Days on Market (Comparables):", comparablesDaysOnMarket);
  console.log("Listing Price:", listing_price);
  console.log("Subject Sqft:", subject_sqft);
  console.log("Listing ID:", listing.listing_id);
  console.log("Estimations:", estimation);
  console.log("Comparables:", comparables);

  return (
    <Row>
      < LinearGauge
        expectedRent={expected_rent}
        actualRent={actual_rent}
        vacancyDays={vacancy_estimate}
        expectedRentPsf={expected_rent_psf}
        actualRentPsf={actual_rent_psf}
      />

      {/* {listing.property_type === "Residential" && (
        <LinearGauge
          expectedRent={parseFloat(rent_prediction.replace(/[^0-9.]/g, ''))}
          actualRent={parseFloat(listing_price_str.replace(/[^0-9.]/g, ''))}
          vacancyDays={comparablesDaysOnMarket ? comparablesDaysOnMarket.toFixed(0) : "unknown"}
          expectedRentPsf={(expected_rent_psf)}
          actualRentPsf={''}
        />
      )} */}
    </Row >
  );
}