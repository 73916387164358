import { formatNumericValue } from "./PriceInput";
import { ProformaTable } from "./ProformaTable";
import { useEffect } from "react";
import {
  OperatingIncomeRow,
  RowProps,
} from "./OperatingIncomeRow";
import { InfoTooltip } from "../../../components/InfoTooltip";
import { Link } from "react-router-dom";
import { styled } from "styled-components";
import { Settings } from "react-feather";

const SettingsLink = styled(Link)`
  color: #8c8c8c;
  text-decoration: none;
`;

const SettingsIcon = styled(Settings)`
  width: 18px;
  height: 18px;
  margin-right: 5px;
`;

interface Props {
  repairAndMaintenance: number | null;
  propertyManagement: number | null;
  listingFees: number | null;
  insurance: number | null;
  other: number | null;
  propertyTax: number | null;
  hoaFee: number | null;

  offerPrice: number;
  insuranceRate: number;
  leaseListingFee: number;
  repairAndMaintenanceRate: number;
  propertyManagementRate: number;
  userTaxRate: number;
  enableTaxUserEstimate: boolean;
  providerEstimatedTaxes: number;
  providerAssosiationFee: number;


  setRepairAndMaintenance: (newValue: number | null) => void;
  setPropertyManagement: (newValue: number | null) => void;
  setListingFees: (newValue: number | null) => void;
  setInsurance: (newValue: number | null) => void;
  setOther: (newValue: number | null) => void;
  setPropertyTax: (newValue: number | null) => void;
  setHoaFee: (newValue: number | null) => void;

  rentEstimate: number;
  netIncome: number;
  onChange: (income: number) => void;
}

export const NetOperatingIncome = (props: Props) => {


  const rows: RowProps[] = [
    {
      key: 'repairAndMaintenance',
      id: 'repairAndMaintenanceInput',
      label: (
        <>
          <InfoTooltip label="It's prudent to expect periodic repairs and maintenance.  We suggest 3% per year as a starting point and may budget more for older single family homes, or less for newer condominiums.">
            Repairs & Maintenance
          </InfoTooltip>
        </>
      ),
      value: props.repairAndMaintenance,
      onUpdate: props.setRepairAndMaintenance,
      getDefaultValue: () => (props.rentEstimate * (props.repairAndMaintenanceRate / 100)),
    },
    {
      label: (
        <>
          <InfoTooltip label='Residential property managers will often charge a percentage of the rent being paid.  We&apos;ve observed these amounts ranging from 7-15% depending on the level of service.  Property managers can help owners who want to be "hands off."  Many owners choose to manage themselves to save the expense.'>
            Property Management
          </InfoTooltip>
        </>
      ),
      key: "propertyManagement",
      id: 'propertyManagementInput',
      value: props.propertyManagement,
      onUpdate: props.setPropertyManagement,
      getDefaultValue: () => (props.rentEstimate * (props.propertyManagementRate / 100)),
    },
    {
      label: (
        <>
          <InfoTooltip label="We've observed agents charging a fee of 75-100% of one month's rent to list a property for lease.">
            Listing Fees
          </InfoTooltip>
        </>
      ),
      key: 'listingFees',
      id: 'listingFeesInput',
      value: props.listingFees,
      onUpdate: props.setListingFees,
      getDefaultValue: () => (props.rentEstimate * (props.leaseListingFee / 100)) / 12,
    },
    {
      label: (
        <>
          <InfoTooltip label='Property tax rates are assessed by local governments.  We default to using the property taxes provided in the MLS listing unless you select "User Estimate" in assumptions where, for simplicity, the calculation uses a percentage multipled by the Offer Price.  You can override either by typing in the property tax estimate you believe to be correct.'>
            Property Taxes
          </InfoTooltip>
        </>
      ),
      key: 'propertyTax',
      id: 'propertyTaxInput',
      value: props.propertyTax,
      onUpdate: props.setPropertyTax,
      getDefaultValue: () => {
        return (props.enableTaxUserEstimate ? (props.offerPrice * props.userTaxRate) / 100 : props.providerEstimatedTaxes) / 12
      }
    },
    {
      label: (
        <>
          <InfoTooltip label='HOA payments, if any, are normalized into monthly and annual amounts.'>
            HOA Fees
          </InfoTooltip>
        </>
      ),
      key: 'hoaFee',
      id: 'hoaFeeInput',
      value: props.hoaFee,
      onUpdate: props.setHoaFee,
      getDefaultValue: () => props.providerAssosiationFee,
    },
    {
      label: (
        <>
          <InfoTooltip label='Home owners insurance is typically required by a lender.  We apply a percentage multipled by the Offer Price.'>
            Insurance
          </InfoTooltip>
        </>
      ),
      key: 'insurance',
      id: 'insuranceInput',
      value: props.insurance,
      onUpdate: props.setInsurance,
      getDefaultValue: () => (props.offerPrice * (props.insuranceRate / 100)) / 12,
    },
    {
      label: (
        <>
          <InfoTooltip label='Use this field to include any fees not shown above.'>
            Other
          </InfoTooltip>
        </>
      ),
      key: 'other',
      id: 'otherInput',
      value: props.other,
      onUpdate: props.setOther,
      getDefaultValue: () => 0,
    },
  ];

  const getOperatingExpenses = () => {
    return -[
      props.repairAndMaintenance ?? 0,
      props.propertyManagement ?? 0,
      props.listingFees ?? 0,
      props.propertyTax ?? 0,
      props.hoaFee ?? 0,
      props.insurance ?? 0,
      props.other ?? 0,
    ].reduce((partialSum, a) => partialSum + a, 0)
  }

  const getOperatingIncome = () => {
    return (
      props.netIncome -
      [
        props.repairAndMaintenance ?? 0,
        props.propertyManagement ?? 0,
        props.listingFees ?? 0,
        props.propertyTax ?? 0,
        props.hoaFee ?? 0,
        props.insurance ?? 0,
        props.other ?? 0,
      ].reduce((partialSum, a) => partialSum + a, 0)
    );
  };

  useEffect(() => {
    props.onChange(getOperatingIncome());
  }, [props]);

  return (
    <ProformaTable>
      <thead>
        <tr>
          <th className='w60'>
            Operating Costs <SettingsLink to="/accounts/preferences"><SettingsIcon /></SettingsLink>
          </th>
        </tr>
      </thead>
      <tbody>
        {rows.map((row, index) => (
          <OperatingIncomeRow
            key={row.key}
            id={row.id}
            label={row.label}
            value={row.value}
            onUpdate={row.onUpdate}
            getDefaultValue={row.getDefaultValue}
          />
        ))}
      </tbody>
      <tfoot>
        <tr>
          <td className='w60' colSpan={3}>
            Operating Expenses
          </td>
          <td className='w20' align='right'>
            <div className='textOnly' id='operatingExpensesMonthly'>
              {formatNumericValue(getOperatingExpenses())}
            </div>
          </td>
          <td className='w20' align='right'>
            <div className='textOnly' id='operatingExpensesYearly'>
              {formatNumericValue(getOperatingExpenses() * 12)}
            </div>
          </td>
        </tr>
        <tr>
          <td className='w60' colSpan={3}>
            Net Operating Income
          </td>
          <td className='w20' align='right'>
            <div className='textOnly' id='netOperatingIncomeMonthly'>
              {formatNumericValue(getOperatingIncome())}
            </div>
          </td>
          <td className='w20' align='right'>
            <div className='textOnly' id='netOperatingIncomeYearly'>
              {formatNumericValue(getOperatingIncome() * 12)}
            </div>
          </td>
        </tr>
      </tfoot>
    </ProformaTable>
  );
};
