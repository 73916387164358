import { Table } from "react-bootstrap";
import { styled } from "styled-components";

export const ProformaTable = styled(Table)`
  td,
  th {
    font-size: 18px;
    color: #333;
    border: none;
    background: none;
    padding-top: 3px;
    padding-bottom: 2px;
    padding-right: 0px;
    font-family: 'Proxima Nova', sans-serif;
  }

  th {
    padding: 0;
    font-weight: 600;
    text-align: right;
  }

  thead tr {
    border-bottom: 1px solid #000;
  }
  tfoot tr:first-child {
    border-top: 1px solid #000;
  }
  td:first-child {
    padding-left: 5px;
  }
  th:first-child {
    text-align: left;
  }
  tfoot tr td:first-child {
    padding-left: 15px;
  }

  .textOnly {
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
    width: 90px;
    padding-right: 5px;
  }

  td {
    width: 35%;

    
    span.redColor {
      color: #FF6058;
    }

    &.withInputs {
      input,
      .form-switch {
        display: inline-block;
        max-width: 60px;
        padding-left: 0px;
        padding-right: 0px;
        float: right;
        margin-right: 20px;
      }
    }
  }

  td:first-child {
    width: 65%;
  }
  .form-control {
    text-align: right;
    border: 1px solid #c8c8ec;
    border-radius: 3px;
    height: 26px;
    line-height: 26px;
    font-size: 18px;
    color: #333;
    padding-right: 5px;
  }
  .input-group-text {
    height: 26px;
    line-height: 26px;
    font-size: 18px;
    color: #333;
    border-radius: 3px;
    background: none;
    border-color: #c8c8ec;
    padding: 0px 0px 0px 2px;
  }
`;