import React, { useState } from "react";
import { createContext, useContext, useMemo } from "react";
import { useAuth } from "../ui/hooks/useAuth";
import { User } from "./interfaces/user";
import {
  Rentomatic,
  ListingCompactView,
  Listing,
  CoreLogicResponse,
} from "./interfaces/listing";
import { Proforma } from "./interfaces/proforma";
import { Dossier } from "./interfaces/dossier";
import { UserPreferences } from "./interfaces/preferences";
import { Bundle } from "./interfaces/modules";
import { NotFoundError } from "common/exceptions";

export const API_URL: string = process.env.REACT_APP_API_URL!;
const BILLING_REDIRECT_HOST = process.env.REACT_APP_BILLING_REDIRECT_URL;

// TODO: Refactor api client to not be dependent on React
// Consuming code should take care of loading state on their own

export interface ApiContextType {
  isLoading: boolean;
  searchEndpointLoading: boolean;
  login: (username: string, password: string) => Promise<void>;
  resetPassword: (email: string) => Promise<void>;
  resetPasswordConfirm: (
    password: string,
    password_confirm: string,
    data: any
  ) => Promise<any>;
  register: (user: Partial<User>) => Promise<any>;
  getTerms: (name: string, email: string) => Promise<any>;
  logout: () => Promise<void>;
  getListings: (
    filters: any,
    offset: number,
    sort_by: string | null,
    limit: number | null,
    abortSignal?: AbortSignal,
    shortPayload?: boolean
  ) => Promise<any>;
  getTopPicks: (city: string) => Promise<any>;
  getAddressFromUrl: (query: string) => Promise<any>;
  parseAddressFromString: (address: string) => Promise<any>;
  getDossier: (listingKey: string) => Promise<any>;
  requestDossier: (listingKey: string, currentUrl?: string) => Promise<any>;
  getDossierList: () => Promise<Dossier[]>;
  getListing: (listingKey: string) => Promise<any>;
  disableListing: (listingKey: string) => Promise<any>;
  getRentomatic: (
    listing: Listing,
    comparablesIds: string[]
  ) => Promise<Rentomatic>;
  gerProformaUrl: (listingKey: string) => Promise<any>;
  getProforma: (listing: Listing) => Promise<Proforma>;
  saveProforma: (listingKey: string, proforma: Proforma) => Promise<Proforma>;
  deleteProforma: (listingKey: string) => Promise<any>;
  getUserData: () => Promise<User>;
  getUserModules: () => Promise<any>;
  getModulesBundles: () => Promise<any>;
  getUserPreferences: () => Promise<UserPreferences>;
  saveUserPreferences: (preferences: UserPreferences) => Promise<void>;
  saveUserData: (user: User) => Promise<any>;
  addToFavorites: (listingKey: string) => Promise<any>;
  removeFromFavorites: (listingKey: string) => Promise<any>;
  getFavorites: () => Promise<ListingCompactView[]>;
  getSubscription: () => Promise<any>;
  manageSubscription: () => Promise<any>;
  createSubscription: (productIds: number[]) => Promise<string>;
  getListingInformation: (dom: string) => Promise<Listing>;
  getSubscriptionStatus: (sessionId: string | null) => Promise<any>;
  searchMetadata: (input_str: string) => Promise<any>;
  getSearchHistory: () => Promise<any>;
  updateSearchHistory: (address: string, clip: string) => Promise<any>;
  getPropertyMetadata: (propertyId: string) => Promise<CoreLogicResponse | null>;
}

const ApiContext = createContext<ApiContextType>({} as ApiContextType);

export class ApiError extends Error {
  constructor(public status: number, public message: string, public details?: any) {
    super(message);
    this.name = "ApiError";
  }
}

export class ThrottleError extends Error {
  constructor(public message: string) {
    super(message);
    this.name = "ThrottleError";
  }
}

class ApiClient {
  constructor(private baseUrl: string, private token: string | null) {}

  async request<T>(
    endpoint: string,
    method: string,
    body?: any,
    signal?: AbortSignal,
    textResponse: boolean = false,
  ): Promise<T> {
    const url = `${this.baseUrl}${endpoint}`;

    const headers: HeadersInit = {
      "Content-Type": "application/json",
    };

    if (this.token) {
      headers["Authorization"] = `Token ${this.token}`;
    }

    const options: RequestInit = {
      method,
      headers,
      credentials: "omit",
    };

    if (body && method !== "GET") {
      options.body = JSON.stringify(body);
    }

    if (signal) {
      options.signal = signal;
    }

    const response = await fetch(url, options);

    if (!response.ok) {
      const errorBody = await response.json().catch(() => ({}));

      if (response.status === 404) {
	throw new NotFoundError(errorBody.detail || "Not found");
      }

      if (response.status === 429) {
	throw new ThrottleError(errorBody.detail || "Too many requests");
      }

      throw new ApiError(response.status, errorBody.detail || "Unknown error", errorBody);
    }

    if (response.status === 204) {
      return {} as T;
    }

    if (textResponse) {
      return await response.text() as T;
    }

    return await response.json();
  }

  async get<T>(endpoint: string, signal?: AbortSignal, textResponse: boolean = false): Promise<T> {
    return this.request<T>(endpoint, "GET", undefined, signal, textResponse);
  }
  
  async post<T>(endpoint: string, data?: any, signal?: AbortSignal, textResponse: boolean = false): Promise<T> {
    return this.request<T>(endpoint, "POST", data, signal, textResponse);
  }
  
  async put<T>(endpoint: string, data: any, signal?: AbortSignal, textResponse: boolean = false): Promise<T> {
    return this.request<T>(endpoint, "PUT", data, signal, textResponse);
  }
  
  async patch<T>(endpoint: string, data: any, signal?: AbortSignal, textResponse: boolean = false): Promise<T> {
    return this.request<T>(endpoint, "PATCH", data, signal, textResponse);
  }
  
  async delete<T>(endpoint: string, signal?: AbortSignal, textResponse: boolean = false): Promise<T> {
    return this.request<T>(endpoint, "DELETE", undefined, signal, textResponse);
  }
}

export const ApiProvider = ({ children }: any) => {
  const authContext = useAuth();
  const [isLoading, setLoading] = useState<boolean>(false);
  const [searchEndpointLoading, setSearchEndpointLoading] =
    useState<boolean>(false);

  const api = useMemo(() => {
    return new ApiClient(API_URL, authContext?.userToken || null);
  }, [authContext?.userToken]);

  const login = async (username: string, password: string) => {
    const data = await api.post<{ token: string }>("/accounts/login/", {
      login: username.toLowerCase(),
      password,
    })

    authContext?.login(data.token);
    await authContext?.getToken();
  };

  const resetPassword = async (email: string) => {
    await api.post("/accounts/send-reset-password-link/", {
      login: email.toLowerCase(),
    })
  };

  const resetPasswordConfirm = async (
    password: string,
    password_confirm: string,
    data: any
  ): Promise<any> => {
    return await api.post("/accounts/reset-password/", {
      password,
      password_confirm,
      ...JSON.parse(atob(data)),
    });
  };

  const register = async (user: Partial<User>) => {
    return await api.post("/accounts/register/", {
        email: user.email ? user.email.toLowerCase() : null,
        password: user.password,
        password_confirm: user.password_confirm,
        first_name: user.first_name,
        last_name: user.last_name,
    })
  };

  const getTerms = async (name: string, email: string) => {
    return await api.post(`/compliance/terms/`, {
      email,
      name,
    }, undefined, true);
  };

  const createSubscription = async (productIds: number[]): Promise<string> => {
    const data = await api.post<{ clientSecret: string}>(`/payment/create/`, {
	productIds,
	redirectHost: BILLING_REDIRECT_HOST
    })

    return data.clientSecret;
  };

  const getSubscriptionStatus = async (
    sessionId: string | null
  ): Promise<any> => {
    return await api.get(`/payment/status/?session_id=${sessionId}`);
  };

  const getSubscription = async (): Promise<any> => {
    return await api.get(`/payment/subscription/`);
  };

  const manageSubscription = async (): Promise<any> => {
    return await api.post(`/payment/subscription/manage/`);
  };

  const logout = async () => {
    await api.post("/accounts/logout/");
    await authContext?.logout();
  };

  const getListing = async (listingKey: string): Promise<any> => {
    setLoading(true);

    try {
      return await api.get(`/listings/${listingKey}/`);
    } finally {
      setLoading(false);
    }
  };

  const disableListing = async (listingKey: string): Promise<any> => {
    setLoading(true);

    try {
      return await api.post(`/listings/${listingKey}/`);
    } finally {
      setLoading(false);
    }
  };

  const getDossier = async (listingKey: string): Promise<any> => {
    setLoading(true);

    try {
      return await api.get(`/dossier/${listingKey}/`);
    } finally {
      setLoading(false);
    }
  };

  const getDossierList = async (): Promise<Dossier[]> => {
    setLoading(true);

    try {
      return await api.get(`/dossier/`);
    } finally {
      setLoading(false);
    }
  };

  const requestDossier = async (
    listingKey: string,
    currentUrl?: string
  ): Promise<any> => {
    setLoading(true);

    try {
      return await api.post(`/dossier/${listingKey}/`, {
	currentUrl: currentUrl,
      });
    } finally {
      setLoading(false);
    }
  };

  const getProforma = async (listing: Listing): Promise<Proforma> => {
    return await api.post(`/proformas/`, listing);
  };

  const saveProforma = async (
    listingKey: string,
    proforma: Proforma
  ): Promise<Proforma> => {

    return await api.post(`/proformas/${listingKey}/`, proforma);
  };

  const getListingInformation = async (dom: string): Promise<Listing> => {
    return await api.post(`/listings/parse/`, { text: dom });
  };
  
  const getAddressFromUrl = async (url_query: string): Promise<Listing> => {
    return await api.post(`/parse/url/`, { url: url_query });
  };
  
  const parseAddressFromString = async (address: string): Promise<Listing> => {
    return await api.post(`/parse/address/`, { address });
  };

  const deleteProforma = async (listingKey: string): Promise<any> => {
    return await api.delete(`/proformas/${listingKey}/`);
  };

  const getRentomatic = async (
    listing: Listing,
    comparablesIds: string[]
  ): Promise<Rentomatic> => {
    return await api.post(`/rentomatic/`, {
      listing,
      comparables_ids: comparablesIds
    })
  };

  const gerProformaUrl = async (listingKey: string): Promise<any> => {
    return await api.get(`/listings/${listingKey}/proforma/`);
  };

  const getUserData = async (): Promise<User> => {
    return await api.get(`/accounts/profile/`);
  };

  const getUserModules = async (): Promise<any> => {
    return await api.get(`/modules/active/`);
  };

  const getModulesBundles = async (): Promise<Bundle[]> => {
    return await api.get(`/modules/bundles/`);
  };

  const getUserPreferences = async (): Promise<UserPreferences> => {
    return await api.get(`/preferences/`);
  };

  const saveUserPreferences = async (
    preferences: UserPreferences
  ): Promise<void> => {
    return await api.post(`/preferences/`, {...preferences});
  };

  const saveUserData = async (user: User): Promise<any> => {
    return await api.patch(`/accounts/profile/`, {...user});
  };

  const getFavorites = async (): Promise<any> => {
    return await api.get(`/user/favorites/`);
  };

  const searchMetadata = async (input_str: string): Promise<any> => {
    return await api.get(`/metadata/search/?input=${input_str}`);
  };

  const updateSearchHistory = async (
    address: string,
    clip: string
  ): Promise<any> => {
    return await api.post(`/metadata/search/history/`, {
      address: address,
      clip: clip,
    })
  };

  const getSearchHistory = async (): Promise<any> => {
    try {
      return await api.get(`/metadata/search/history/`);
    } catch (error) {
      console.error(error);
      return [] 
    }
  };

  const getPropertyMetadata = async (
    propertyId: string
  ): Promise<CoreLogicResponse | null> => {
    return await api.get(`/metadata/property/${propertyId}/`);
  };

  const addToFavorites = async (listingKey: string): Promise<any> => {
    setLoading(true);
    try {
      return await api.post(`/user/favorites/${listingKey}/`);
    } finally {
      setLoading(false);
    }
  };

  const removeFromFavorites = async (listingKey: string): Promise<any> => {
    setLoading(true);
    try {
      return await api.delete(`/user/favorites/${listingKey}/`);
    } finally {
      setLoading(false);
    }
  };

  const getListings = async (
    filters: any,
    offset: number,
    sort_by: string | null,
    limit: number | null,
    abortSignal?: AbortSignal,
    shortPayload?: boolean,
  ): Promise<any> => {
    setSearchEndpointLoading(true);
    const url = `/listings/?offset=${offset}&sort_by=${sort_by}&limit=${limit}&short_payload=${shortPayload}`;

    try {
      return await api.post(url, filters, abortSignal);
    } finally {
      setSearchEndpointLoading(false);
    }
  };

  const getTopPicks = async (
    city: string,
  ): Promise<any> => {
    const url = `/listings/top_picks/${city}/`;

    try {
      return await api.get(url);
    } finally {
      setSearchEndpointLoading(false);
    }
  };

  const value = useMemo(
    () => ({
      isLoading,
      searchEndpointLoading,
      login,
      register,
      getTerms,
      logout,
      resetPassword,
      resetPasswordConfirm,
      getListing,
      disableListing,
      getDossier,
      getDossierList,
      requestDossier,
      getProforma,
      saveProforma,
      deleteProforma,
      getRentomatic,
      getFavorites,
      getUserData,
      getUserModules,
      getModulesBundles,
      getUserPreferences,
      saveUserPreferences,
      saveUserData,
      addToFavorites,
      getListings,
      getTopPicks,
      removeFromFavorites,
      getSubscription,
      createSubscription,
      manageSubscription,
      getSubscriptionStatus,
      gerProformaUrl,
      getListingInformation,
      getAddressFromUrl,
      parseAddressFromString,
      searchMetadata,
      getPropertyMetadata,
      getSearchHistory,
      updateSearchHistory,
    }),
    [authContext?.userToken, isLoading, searchEndpointLoading]
  );
  return <ApiContext.Provider value={value}>{children}</ApiContext.Provider>;
};

export const useApi = () => {
  return useContext(ApiContext);
};
