import styled from "styled-components";
import { Listing } from "../../../../api/interfaces/listing";
import { USDollar } from "../../../utils";
import { PageSubTitle } from "../../../components/layout/PageSubtitle";
import { InfoTooltip } from "../../../components/InfoTooltip";

interface Props {
  listing?: Listing;
  creamScore?: number | null;
}

const Wrapper = styled.div`
  height: 340px;
  border: 1px solid #ffb41d;
  padding: 10px 0px;
  color: #000;
  overflow-y: scroll;
  margin-top: 5px;
  border-radius: 8px;
  background: #fff;
`;

const Title = styled.div`
  font-size: 16px;
  font-weight: bold;
  color: #000;
  padding: 0px 12px;
  min-height: 50px;
  border-bottom: 1px dashed #000;
`;

const Description = styled.div`
  padding: 10px 12px;
  font-size: 10px;
  font-weight: bold;

  .largeFont {
    font-size: 12px;
  }
`;

const CreamScore = styled.div`
  font-size: 12px !important;
`;

export const SelectedProperty = (props: Props) => {
  const { listing, creamScore } = props;

  const blockTitle = (
    <PageSubTitle title="Subject Property" button={creamScore && creamScore >= 0 && (
      <InfoTooltip
        placement='top'
        label='100 is best, 0 is worst, relative to all other listings in Texas'
      >
        <CreamScore>CREAM Score: {creamScore}</CreamScore>
      </InfoTooltip>
    )}>
      <div>Subject property</div>

    </PageSubTitle>
  );

  if (!listing) {
    return (
      <>
        {blockTitle}
        <Wrapper>
          <Title>None selected</Title>
        </Wrapper>
      </>
    );
  }
  return (
    <>
      {blockTitle}
      <Wrapper>
        <Title>
          {listing.full_address}
          <br />
          {listing.city}, {listing.state} {listing.postal_code}
        </Title>
        <Description>
          <div className='largeFont'>Status: {listing.standard_status}</div>
          <div className='largeFont'>Community: {listing.subdivision_name}</div>
          <div className='largeFont'>Living Area: {listing.living_area}</div>
          <div className='largeFont'>Bedrooms: {listing.bedrooms_total}</div>
          <div className='largeFont'>
            Baths: {listing.bathrooms_total_decimal}
          </div>
          <div className='largeFont'>
            Original List Price:{" "}
            {listing.original_list_price
              ? USDollar.format(listing.original_list_price)
              : ""}
          </div>
          <div className='largeFont'>
            Days on Market: {listing.days_on_market}
          </div>
          <br />
          <div>Source: {listing.data_source}</div>
          <div>MLS: {listing.listing_id}</div>

          {listing.last_modified_date && (
            <div>
              Last Changed:{" "}
              {new Date(listing.last_modified_date).toLocaleDateString("en-US")}
            </div>
          )}
          <div>Listed By: {listing.list_agent_full_name}</div>
          <div>Brokered By: {listing.list_office_name}</div>
          <div>
            Based on information from {listing.data_source} for the period
            through {new Date(listing.updated_at).toLocaleDateString("en-US")}.
            Neither the Board nor {listing.data_source} guarantees or is in any
            way responsible for its accuracy. All data is provided “AS IS” and
            with all faults
          </div>
        </Description>
      </Wrapper>
    </>
  );
};
