import React, { useState } from "react";
import { createContext, useContext, useMemo } from "react";
import { Listing } from "../../api/interfaces/listing";

export type ListingContextType = {
  listing: Listing | null;
  setListing: (listing: Listing) => void;
  
  listingKey: string | null;
  setListingKey: (listingKey: any) => void;

  clear: () => void;
};

const ListingContext = createContext<ListingContextType>({} as ListingContextType);

export const ListingProvider = ({ children }: any) => {
  const [listingKey, setListingKey] = useState<string | null>(null);
  const [listing, setListing] = useState<Listing | null>(null);
  const clear = () => {
    setListingKey(null);
    setListing(null);
  }
  const value = useMemo(
    () => ({
      listingKey,
      setListingKey,
      listing,
      setListing,
      clear
    }),
    [listingKey, listing]
  );
  return (
    <ListingContext.Provider value={value}>{children}</ListingContext.Provider>
  );
};

export const useListing = () => {
  return useContext(ListingContext);
};
