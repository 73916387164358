import { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import MultiRangeSlider from "multi-range-slider-react";
import { Slider } from "./Slider";
import { formatPercent, USDollar } from "../../../utils";
import { CreamTypes } from "../../../interfaces";

const MAX_PRICE = 5000000;
const MAX_DAYS_ON_MARKET = 500;

interface Props {
  onChange: (filters: CreamTypes.Filters) => void;
  filters: CreamTypes.Filters;
}

const formatPrice = (value: number) => {
  if (value === MAX_PRICE) {
    return "no max";
  }
  return USDollar.format(value);
};

const formatMaxDaysOnMarket = (value: Number) => {
  if (value >= MAX_DAYS_ON_MARKET) {
    return "no max";
  }
  return value.toString();
}

export const SearchFilters = (props: Props) => {
  const [minPrice, setMinPrice] = useState<number>(
    props.filters.list_price_min!
  );
  const [maxPrice, setMaxPrice] = useState<number>(
    props.filters.list_price_max === null ? MAX_PRICE : props.filters.list_price_max
  );
  const [minRent, setMinRent] = useState<number>(
    props.filters.estimated_rent_min!
  );
  const [minReturn, setMinReturn] = useState<number>(
    props.filters.estimated_return_min!
  );
  const [minDaysOnMarket, setMinDaysOnMarket] = useState<number>(
    props.filters.days_on_market_min === null ? 0 : props.filters.days_on_market_min

  );
  const [maxDaysOnMarket, setMaxDaysOnMarket] = useState<number>(
    props.filters.days_on_market_max === null ? MAX_DAYS_ON_MARKET : props.filters.days_on_market_max
  );

  const handlePriceInput = (e: any) => {
    setMinPrice(e.minValue);
    setMaxPrice(e.maxValue);
  };

  const handleDaysOnMarketInput = (e: any) => {
    setMinDaysOnMarket(e.minValue);
    setMaxDaysOnMarket(e.maxValue)
  };

  useEffect(() => {
    props.onChange({
      estimated_rent_min: minRent,
      estimated_return_min: minReturn,
      list_price_max: maxPrice >= MAX_PRICE ? null : maxPrice,
      list_price_min: minPrice,
      days_on_market_min: minDaysOnMarket <= 0 ? null : minDaysOnMarket,
      days_on_market_max: maxDaysOnMarket >= MAX_DAYS_ON_MARKET ? null : maxDaysOnMarket,
    });
  }, [maxPrice, minPrice, minRent, minReturn, minDaysOnMarket, maxDaysOnMarket]);

  return (
    <>
      <Row id="priceSlider">
        <Col>
          <div className='sliderWrapper'>
            <div>MIN AND MAX PRICE</div>
            <MultiRangeSlider
              min={100000}
              max={MAX_PRICE}
              step={100000}
              stepOnly
              minValue={minPrice}
              maxValue={maxPrice}
              onInput={(e) => {
                handlePriceInput(e);
              }}
            />
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <div className="priceLabel">{formatPrice(minPrice)}</div>
              <div className="priceLabel">{formatPrice(maxPrice)}</div>
            </div>
          </div>
        </Col>
      </Row>

      <Row id='rentSlider'>
        <Col>
          <Slider
            min={0}
            step={200}
            max={6000}
            label='Rent min'
            value={props.filters.estimated_rent_min}
            formatFn={(val) => USDollar.format(val) + " and up"}
            onChange={setMinRent}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Slider
            min={-25}
            step={1}
            max={25}
            label='Return min'
            value={props.filters.estimated_return_min}
            formatFn={(val) => formatPercent(val) + " and up"}
            onChange={setMinReturn}
          />
        </Col>
      </Row>

      <Row>
        <Col>
          <div className='sliderWrapper'>
            <div>DAYS ON THE MARKET</div>
            <MultiRangeSlider
              min={0}
              max={MAX_DAYS_ON_MARKET}
              step={1}
              stepOnly
              minValue={minDaysOnMarket}
              maxValue={maxDaysOnMarket}
              onInput={(e) => {
                handleDaysOnMarketInput(e);
              }}
            />
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <div className="priceLabel">{minDaysOnMarket}</div>
              <div className="priceLabel">{formatMaxDaysOnMarket(maxDaysOnMarket)}</div>
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
};
